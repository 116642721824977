@font-face {
  font-family: 'Helvetica';
  src: url('./assets/font/Helvetica.eot');
  src: url('./assets/font/Helvetica.eot?#iefix') format('embedded-opentype'),
    url('./assets/font/Helvetica.woff2') format('woff2'),
    url('./assets/font/Helvetica.woff') format('woff'),
    url('./assets/font/Helvetica.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica';
  src: url('./assets/font/Helvetica-Bold.eot');
  src: url('./assets/font/Helvetica-Bold.eot?#iefix') format('embedded-opentype'),
    url('./assets/font/Helvetica-Bold.woff2') format('woff2'),
    url('./assets/font/Helvetica-Bold.woff') format('woff'),
    url('./assets/font/Helvetica-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}


body {
  margin: 0;
  padding: 0;
  font-family: Helvetica !important;
  font-weight: 400;
  background: #fff;
  color: #000000;
  overflow-x: hidden;
}

button {
  font-family: "Outfit" !important;
  text-transform: capitalize !important;
}

a {
  color: #7ec6ef;
  text-decoration: none;
}

a:hover {
  color: #7ec6ef;
  text-decoration: none;
}

/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #7ec6ef;
}

img.brand_logo {
  width: 160px;
}

.navbar-sticky--moved-up {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff !important;
  margin-top: -100px;
  -webkit-box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
}

.navbar-sticky--on {
  margin-top: 0;
}

.navbar-sticky--on h2.page_title {
  color: #0f4ba7;
}

.navbar-sticky--transitioned {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

/* Dashboard Css */
.apppage_wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.app_page {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}

.app_header {
  z-index: 100;
  position: fixed;
  left: 300px;
  right: 0;
  top: 0;
  background: transparent;
  transition: none;
  display: flex;
  align-items: stretch;
  padding: 24px 0;
}

.app_header .container-fluid {
  padding-right: 24px;
}

.app_header .offcanvas-body,
.app_header .offcanvas-body .navbar-nav {
  align-items: center;
  gap: 16px;
}

.page_title {
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}


button.navbar-toggler {
  box-shadow: none !important;
}

.offcanvas-header .btn-close {
  filter: invert(1);
  box-shadow: none !important;
}

.app_sidebar {
  background: #fff;
  border-right: 0;
  width: 300px;
  position: sticky;
  padding-right: 24px;
  padding-left: 24px;
  padding-bottom: 24px;
  z-index: 105;
  top: 0;
  bottom: 0;
  left: 0;
  transition: width 0.3s ease;
  height: 100%;
}

.page_wrapper {
  display: flex;
  flex: 1 auto;
  min-width: 0;
}

.bg_top::before {
  content: "";
  background: url("./assets/images/top_bg.png") no-repeat top center;
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: 100% 300px;
  z-index: -1;
  top: -110px;
}
.bg_top_chg::before{
  background-size: 100% 200px;
}
.prf_bg{
  background: #f5f5f5 !important;
}
.prf_bg::before {
  display:  block !important;
}
.app_sidebar_menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 180px);
  border-top: 1px solid #E9E9E9;
  padding-top: 24px;
  height: 100%;
}

.app_sidebar_logo {
  display: flex;
  align-items: center;
  padding: 24px 0;
}

ul.sidebar_menu {
  padding-left: 0;
}

ul.sidebar_menu li+li {
  margin-top: 2px;
}

ul.sidebar_menu li a.nav-link {
  color: #9c9c9c;
  font-size: 16px;
  font-weight: 400 !important;
  line-height: normal;
  letter-spacing: 1.8px;
  text-decoration: none;
  width: 100%;
  display: flex;
  padding: 16px 20px;
  align-items: center;
  border-radius: 10px !important;
  position: relative;
  background: transparent;
  box-shadow: none;
  transition: 0.2s all;
}

ul.sidebar_menu li a.nav-link.active,
ul.sidebar_menu li a.nav-link:hover {
  background: #0F4BA7;
  box-shadow: 0px 0px 8px 0px rgba(122, 215, 255, 0.45);
  color: #FFF;
  transition: .2s ease-out background;
}


ul.sidebar_menu li svg,
ul.sidebar_menu li img {
  margin-right: 10px;
}

ul.sidebar_menu li a.nav-link.active svg path,
ul.sidebar_menu li a.nav-link:hover svg path {
  stroke: #fff;
}

ul.sidebar_menu li a.nav-link.active img,
ul.sidebar_menu li a.nav-link:hover img {

  filter: brightness(100);
}

ul.sidebar_menu li a.nav-link.btn-toggle::after {
  width: 1.25em;
  line-height: 0;
  content: url("../src/assets/images/menu_caret_icon.svg");
  transition: transform 0.35s ease;
  transform-origin: 0.5em 50%;
  position: absolute;
  right: 10px;
  filter: invert(1);
}

ul.sidebar_menu li a.nav-link.btn-toggle[aria-expanded="true"]::after {
  transform: rotate(90deg);
}



h2.page_title {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  line-height: normal; 
  margin-bottom: 0;
  text-transform: capitalize;
}


.red_text {
  color: #E34A4A !important;
}

.green_text {
  color: #5BE38A !important;
}

.blue_text {
  color: #87D4F6 !important;
}






.react-datepicker {
  background-color: #ffffff !important;
  color: #000 !important;
  border: 1px solid #e9e9e9 !important;
}

.react-datepicker__header {
  background-color: #ffffff !important;
  border-bottom: 1px solid #e9e9e9 !important;
  color: #000000 !important;
}

.react-datepicker__time-container .react-datepicker__time {
  background-color: #fff !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000 !important;
}

.react-datepicker__time-container {
  border-left: 1px solid #e9e9e9 !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: #e9e9e9 !important;
}

.react-datepicker__navigation {
  top: 8px !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: #fff !important;
  color: #000 !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #fff !important;
  color: #000 !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  background-color: #fff !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #1b62aa !important;
  color: #fff !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #7EC6EF !important;
  color: #111 !important;
}

.topnavbar_right {
  display: flex;
  align-items: center;
  gap: 30px;
}

.topnavbar_right i.fa-bars,
.app_sidebar i.close_icon {
  display: none;
}

.admin_profile img {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.auth_header ul.navbar-nav {
  gap: 16px;
}

.auth_header ul li a {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}

.auth_header ul li a:hover {
  color: #7ec6ef;
}

/* .iWyFZZ div:first-child {
  white-space: pre-line !important;
} */

/* Login */
.auth_wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.auth_main {
  display: flex;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  margin-bottom: -25px;
}

nav.navbar.navbar-expand-xl.navbar-dark.auth_header {
  z-index: 100;
}



.auth_left {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: 50px;
  color: #707070;
}

.logo_white {
  position: absolute;
  top: 20px;
  left: 30px;
}

.auth_left h2 {
  margin-bottom: 0;
  color: #0F4BA7;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.auth_left h2 span {
  font-weight: 700;
}

.auth_left label {
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}

.register_here {
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-top: 40px;
}

.register_here a {
  color: #0F4BA7;
  text-decoration-line: none;
}



.auth_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0;
}

.auth_footer label {
  margin-bottom: 0;
}

.auth_footer a {
  color: #0F4BA7;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: none;
}


.auth_form .form-group {
  margin-bottom: 20px;
}

.auth_main_forgot {
  background: #000;
  padding: 70px;
  justify-content: center;
}

.auth_main_forgot .auth_left {
  width: 50%;
  color: #707070;
}

.auth_main_forgot .auth_left img {
  width: 150px;
  margin-bottom: 40px;
}

.auth_main_forgot .auth_left h2 {
  font-size: 32px;
}



.auth_left button a {
  color: #11151a !important;
}


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}





















.primary_btn {
  border-radius: 10px;
  padding: 12px 30px;
  color: #212121;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  border: 0;
  outline: 0;
  box-shadow: none !important;
  background: #FFF;
}

.blue_btn button {
  padding: 12px 30px;
  color: #fff;
  font-family: "Outfit";
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  text-transform: capitalize;
  border-radius: 10px;
  background: #0F4BA7 !important;
  box-shadow: none !important;
}

.blue_btn button a {
  color: #fff !important;
}
.grey_btn button {
  border-radius: 10px;
  background: #E3E3E3 !important;
  color: #707070;
}
.cursor-pointer {
  cursor: pointer;
}
.word-break{
  word-break: break-word;
}
.grey {
  color: #B5BFD0;
}
.darkgrey{
  color: #707070;
}
.read.darkgrey{
  color: #707070 !important ;
}
.blue {
  color: #0F4BA7 !important;
}
.font_700{
  font-weight: 700 !important;
}

.line {
  border: 1px solid #e9e9e9;
  border-bottom: 0;
}

.page_content {
  background: #f5f5f5;
  z-index: 999;
  position: relative;
  flex: 1;
  margin-top: 110px;
}

.page_content .container-fluid {
  padding-left: 24px;
  padding-right: 24px;
}



.idbox {
  color: #232323;
  font-family: Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 10px;
  background: #F6F8FA;
  padding: 10px;
  margin-bottom: 24px;
  display: inline-block;
}

.notification_link {
  background: #fff;
  border-radius: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user_drp {
  border-radius: 10px;
  background: #F6F8FA;
  color: #232323 !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px;
  padding-right: 30px !important;
  box-shadow: none !important;
}

.user_drp::before {
  content: "";
  background: url("./assets/images/drp_arw.png") no-repeat center;
  position: absolute;
  right: 7px;
  top: 0;
  bottom: 0;
  width: 10px;
  height: 7px;
  margin: auto 0;
}

.user_drp img {
  width: 20px;
}

.user_drp+ul {
  background: white;
  border-color: #e7e7e7;
  left: unset !important;
  right: 0;
}

.user_drp+ul .dropdown-item {
  color: black !important;
}

.user_drp+ul .dropdown-item:hover {
  transition: .3s;
  background: #0000000d;
}





.footer {
  background: #062A60;
  color: #fff;
  padding: 30px 0;
  position: relative;
  z-index: 101;
  margin-bottom: -25px;
}
.footer>div {
  padding: 0 50px;
}


.footer_panel_top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  margin-bottom: 15px;
  padding-bottom: 20px;
}

.footer ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul.footer_social_links {
  display: flex;
  gap: 20px;
}

ul.footer_social_links li a {
  background: #B5BFD0;
  color: #4C586C;
  width: 36px;
  height: 36px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s all;
  text-decoration: none;
}

ul.footer_social_links li a:hover {
  background: #fff;
  color: #0F4BA7;
}

.footer_about h5 {
  color: #FFF;
  font-family: Helvetica;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.footer_about >div{
  display: flex;
  gap: 80px;
  margin-bottom: 20px;
}
.footer_panel_bottom {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.footer_panel_bottom p {
  font-size: 15px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 0;
}

.footer .ultag li a {
  color: #B5BFD0 !important;
}

.blue_box {
  border-radius: 20px;
  background: #062A60;
  color: #fff; 
  position: relative;
  z-index: 1;
  padding-top: 24px;
}

.blue_box::before {
  content: "";
  position: absolute;
  background: url("./assets/images/boximg.png") no-repeat right top;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
}

.blue_box h5 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.blue_box .coin {
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.blue_box h3 {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}
.blue_box .butns{
   padding: 0 24px;
   padding-bottom: 24px;
   position: relative;
}
.blue_box .butns::before{
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: rgba(35, 35, 35, 0.16);
  width: 1px;
  height: 75%;
}


.right_box {
  border-radius: 20px;
  background: linear-gradient(180deg, #18449D 0%, #062A60 100%);
  color: #fff;
  padding: 24px;
  text-align: center;
}

.right_box h4 {
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  /* 145.455% */
  text-transform: capitalize;
}

.right_box_chg{
  text-align: left;
  display: flex;
    align-items: center;
}
.right_box_chg h4{
  font-size: 26px;
}
.right_box_chg .usdt_img{
  margin-left: -30px;
}
.transaction_box {
  border-radius: 20px;
  background: #FFF;
  padding: 24px;
  width: 100%;
}

.transaction_box h5 {
  color: #212121;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.transaction_box h5 a {
  color: #212121;
}

.transaction_box td {
  font-size: 18px;
  padding: 15px 0;
  border-color: #e9e9e9;
  white-space: nowrap;
}
.transaction_box td:nth-child(1){
  padding-right: 20px;
}
.transaction_box td .grey {
  color: #707070;
}



.title {
  color: #0F4BA7;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.h3tag {
  color: #FFF;
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  text-transform: capitalize;
}

.para {
  font-size: 18px;
  color: #fff;
}

.ultag {
  display: inline-flex;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style-type: none;
  gap: 50px;
}

.ultag li {
  position: relative;
}

.ultag li:not(:last-child):before {
  content: "";
  position: absolute;
  background: #707070;
  height: 57%;
  width: 1px;
  right: -24px;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.ultag li a {
  color: #707070 !important;
}

.auth_right {
  background: linear-gradient(180deg, #18449D 0%, #062A60 100%);
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* input  */
.input_box .form-control {
  border-radius: 10px;
  border: 0px !important;
  background: #f6f8fa !important;
  backdrop-filter: blur(2.5px);
  padding: 20px;
  color: #000 !important;
  box-shadow: none !important;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.input_box .form-control::placeholder {
  color: #9C9C9C !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input_box input:-webkit-autofill {
  -webkit-text-fill-color: #000000;
  background: rgba(255, 0, 0, 0) !important;
  transition: background-color 5000s ease-in-out 0s;
}


.input_box .form-control+.input-group-text {
  border-radius: 0 10px 10px 0;
  background: #f6f8fa !important;
  border: 0 !important;
  backdrop-filter: blur(2.5px);
}
.input_box .blue{
  color: #0F4BA7 !important;
}


/* check box  */
.check_box .form-check-input {
  box-shadow: none;
  border-radius: 5px;
  border: 1.5px solid #707070 !important;
  width: 20px;
  height: 20px;
}

/* select box  */
.drp_box button {
  background: #f6f8fa !important;
  border: 1px solid transparent !important;
  width: 100%;
  padding: 18px;
  height: 61px;
  border-radius: 10px;
  text-align: left;
}
.drp_box button::after {
    border-top: 0.3em solid #707070;
    position: absolute;
    right: 11px;
    bottom: 0;
    top: 0;
    margin: auto;
    height: 4px;
}
.drp_box button + div{
  width: 100%;
}








.primary_modal .modal-dialog{
  max-width: 500px;
}
.primary_modal .modal-content{
 border-radius: 20px;
 border: 0;
}
.primary_modal .modal-header{
  padding: 40px;
  padding-bottom: 0;
  border: 0;
}
.primary_modal .modal-header .btn-close{
  background-image: url("./assets/images/close.png");
  opacity: 1;
  box-shadow: none !important;
}
.primary_modal .modal-title{  
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.primary_modal .modal-body{
 padding: 40px;
}
.primary_modal input,
.primary_modal .input-group-text{
  font-size: 18px !important;
}
.modal-backdrop{
  background: rgba(15, 75, 167, 0.75);
}
.qr_box {
  border-radius: 10px;
  border: 1px solid #EDEDED;
  display: flex;
  padding: 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}


.wallet_box {
  border-radius: 20px;
  background: #062A60;
  color: #fff; 
  position: relative;
  z-index: 1;
  padding:48px;
}
.wallet_box::before {
  content: "";
  position: absolute;
  background: url("./assets/images/boximg.png") no-repeat right top;
  background-size: 170px;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
}
.wallet_box .flx{
  display: flex; 
  gap: 40px;
}
.wallet_box .balance p{
  color: #5F92E0; 
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.wallet_box  h6{ 
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom: 0;
}
.wallet_box  h6 span{
  font-size: 14px;
font-size: 14px;
font-style: normal;
font-weight: 400;
margin-left: 10px;
line-height: normal;
}
.wallet_box  h6.grey{ 
  font-weight: 400;
}


.profile_box{
  border-radius: 20px;
background: #062A60;
padding: 24px;
}
.profile_box .flx{
  display: flex;
  align-items: center;
}
.profile_box .box{
  display: flex;
  padding: 24px;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
  width: 33.3333%;
  position: relative;
}
.profile_box .box:not(:last-child):before {
  content: "";
  position: absolute;
  right: 20px;
  top: 0;
  background: #254A81;
  height: 60%;
  display: block;
  bottom: 0;
  margin: auto 0;
  width: 1px;
}
.profile_box .box p{
  color: #5F92E0;
font-size: 18px; 
font-weight: 400;
margin-bottom: 5px;
}
.profile_box .box h5{  
  color: #fff;
font-size: 18px; 
font-weight: 400; 
word-break: break-word;
}


.setting_box{
  display: flex;
align-items: center;
gap: 24px;
border-radius: 20px;
background: #FFF;
padding: 24px;
}
.setting_box h5{
  color: #232323; 
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.setting_box p{
  color: #9C9C9C; 
font-size: 16px;
font-style: normal;
font-weight: 400;
margin-bottom: 0;
line-height: normal;
}

.custom_tab .nav{
  border: 0;
}
.custom_tab .nav-link {
  border-radius: 10px 0px 0px 10px;
border: 1px solid #0F4BA7 !important;
color: #0F4BA7;
width: 130px;
padding: 11px 15px;
text-align: center;
}
.custom_tab .nav-link.active { 
background: #0F4BA7;
color: #fff;
}
.custom_tab .nav-item:nth-child(2) .nav-link {
  border-radius: 0px 10px 10px 0px;
}


.custom_date {
  border-radius: 10px;
  background: #F6F8FA;
  backdrop-filter: blur(2.5px);
  /* display: flex; */
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px; 
  height: 48px;
}
.custom_date .date_icon{
  margin-top: -3px;
  margin-right: 8px;
  width: 20px;
}
.custom_date input{
  outline: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
  background: #F6F8FA;
  backdrop-filter: blur(2.5px);
  font-family: Helvetica !important;
  padding: 0 !important;
  margin-top: 4px;
  color: #707070; 
font-size: 16px; 
}


.custom_faq{
  border-radius: 20px;
background: #FFF;
padding: 24px;
}
.custom_faq h5{
  color: #212121;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.custom_faq .accordion-item{
  border: 0;
}
.custom_faq .accordion-button{
  color: #707070;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
background-color: transparent;
border: 0;
box-shadow: none !important;
}
.custom_faq .accordion-button::after{
  background-image: url("./assets/images/right_arw.png");
  width: 7px;
  height: 11px;
  background-size: contain;
}
.custom_faq  [aria-expanded="true"]::after{
  transform: rotate(90deg) !important;
}
.custom_faq .accordion-item.active{
  background: #F6F8FA;
  border-radius: 10px;
}
.custom_faq .accordion-item.active .accordion-button{
  color: #1D1D1D;
}
.custom_faq .accordion-body{
  padding-top: 0;
}



.mob_menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  z-index: 500;
  background: #fff;
  padding: 24px;
}
.mob_menu .nav-link{
  text-align: center;
  font-size: 14px;
  color: #9C9C9C;


}
.mob_menu .nav-link.active{
  text-align: center;
  color: #0F4BA7;
}
.mob_menu .nav-link svg{
  margin-bottom: 10px;
}
.mob_menu .nav-link.active svg path{
  stroke: #0F4BA7; 
}
.mob_menu .nav-link.wallet.active svg path{
  stroke: #0F4BA7;
  fill: #0F4BA7;
}
.mob_menu .nav-link p{
  margin-bottom: 0;
}

.dot {
  background: #1b60aa;
  min-width: 6px;
  height: 6px;
  border-radius: 50%;
}
.notes{ 
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


.acc_flx p{ 
  margin-bottom: 0;
}
 
 
 
   /* Styling for chat messages */
  .chat_container { 
     border: 1px solid #e9e9e9;
     border-radius: 5px;
     overflow: hidden;
   }
   
   .chat_messages {
     max-height: 300px;
     overflow-y: auto;
     padding: 10px; 
   }
   
   .message {
     display: flex;
     flex-direction: column;
     align-items: flex-start;
     margin-bottom: 10px;
   }
   .message.admin {
     align-items: flex-end; 
   }
   .message_text {
    background-color: #dbdee3;
     padding: 8px 12px;
     border-radius: 8px;
     max-width: 75%;
     font-size: 14px;
   }
   
   .message_time {
     font-size: 12px;
     color: var(--text_grey);
     margin-top: 5px;
   }
   
  .chat_input {
     display: flex;
     align-items: center;
     padding: 10px; 
     width: 100%;
     position: relative;     
   }
   
   .chat_input input {  
     border-radius: 5px;
     padding-right: 50px !important;
     margin-right: 10px;
   }
   
   .chat_input button {   
     border: none;
     border-radius: 5px;
     padding: 15px 15px;
     cursor: pointer;
     transition: background-color 0.2s;
   }
    
 
.page-link{
  box-shadow: none !important ;
  color: #5f5f5f;
  font-size: 15px;
}
.page-item:last-child .page-link,
.page-link:focus,
.page-link:hover{
  color: #000 !important;
}
.active>.page-link, .page-link.active{
  background-color: #b5bfd0;
  border-color: #b5bfd0;
  color: #000;
}

.hlp_head{
    color: #212121;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-left: 20px;
    margin-top: 30px; 
}
.hash{
  font-size: 16px;
}
.hash:hover{
  color: #202020 !important;
}
.contact:hover{
 color: #fff !important;
}

.phn_inp{
  z-index: 10;
  position: relative;
}

.react-tel-input{
  padding-left:0 ;
  padding: 12px !important;
}
.react-tel-input .flag-dropdown{
  background-color: #f6f8fa !important;
  border: 0 !important;
  border-radius: 0 !important;
}
.react-tel-input .flag-dropdown.open,
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus,
.react-tel-input .flag-dropdown.open .selected-flag{
  background: #f6f8fa !important;
}


.logo_anime{
  width: 200px;
}
.choose_file_btn input[type=file] {
  opacity: 0;
  position: absolute;
  right: 86px;
  width: 50px;
  top: 20px;
}
.attach_icon {
  width: 20px;
  position: absolute;
  right: 110px;
  top: 30px;
}
.dash_title_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dash_title_flex h5 {
  margin-bottom: 0;
}
.dash_title_flex button.primary_btn {
  padding: 10px 15px;
}
.blue_box h5 small {
  font-size: 15px;
}
.wallet_box h6 small {
  font-size: 14px;
}



/* Media Queries */


@media screen and (max-width: 1199px) {
  .h3tag{
    font-size: 26px;
  }
  .app_header {
    background: white;
    padding: 5px 0;
    left: 0;
  }
  .logo_anime{
    width: 160px;
  }
  img.brand_logo {
    width: 130px;
  }

  .user_drp {
    padding-right: 10px !important;
  }

  .user_drp::before {
    display: none;
  }
  .navbar-expand .navbar-nav .dropdown-menu{
    left: -120px;
  }
  .bg_top::before{
    display: none;
  }
  .topnavbar_right i.fa-bars {
    display: block;
    font-size: 20px;
  }
  .blue_box::before{
    background-size: 160px;
  }
  .blue_box .butns {
    padding: 0;
  }
  .blue_box .butns button{
    width: 50%;
    flex-direction: column;
    border: 1px solid #dbdbdb;
    border-top: 0;
    border-radius: 0  ;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .blue_box .butns button:nth-child(1){
    border-right: 0;
    border-bottom-left-radius:18px ;
  }
  .blue_box .butns button:nth-child(2){
    border-left: 0;
    border-bottom-right-radius:18px ;
  }
  .chart_box {
    padding: 0;
    border-radius: 0;
  }
  .chart_box .img_box{
    background: #F6F8FA;
    padding: 20px;
    border-radius: 20px;
  }
  .transaction_box{
    padding: 0;
  }
  .transaction_box h5 a{
    color: #707070;
    font-weight: 400;
  }
  .page_content{
    background: #ffffff;
    margin-top: 80px;
    padding-bottom: 5px;
  }
  .profile_box .flx{
    flex-direction: column;
    align-items: flex-start;
  }
  .profile_box .box{
    width: 100%;
  }
  .profile_box .box:not(:last-child):before{
    bottom: 0;
    width: 100%;
    height: 1px;
    top: unset;
    right: unset;
    left: 0;
  }
  .prf_bg::before {
    background-size: 100% 290px;
    top: -30px;
  }
  .app_sidebar {
    /* left: -250px;
    transition: left 0.3s ease; */
    display: none;
  }

  .page_wrapper {
    margin-left: 0;
    display: block;
  }

  .app_sidebar.show {
    left: 0;
  }

  body.menu_overlay {
    overflow: hidden;
    position: relative;
  }

  body.menu_overlay::before {
    content: "";
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 101;
  }

  .app_sidebar i.close_icon {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 24px;
    display: block;
  }

  .offcanvas {
    background: #0e1116;
  }

  .auth_left {
    width: 70%;
  }
  .footer {
    margin-bottom: 70px;
  }
  .footer_panel_top{
    flex-direction: column;
    font-size: 14px;
    border-bottom: 0;
    margin-bottom: 10px;
    padding-bottom: 0;
  }
  .footer_panel_top div{
    font-size: 14px;
    margin-bottom: 20px;
   }
   .footer_panel_bottom p,
   .footer .ultag li a {
    font-size: 14px;
   }
   .footer_panel_bottom {
    flex-direction: column;
    gap: 5px;
  }
  .footer ul {
    gap: 10px;
  }
  .footer >div{
    padding: 0 30px;
  }
 .footer .ultag li:not(:last-child):before {
    right: -5px;
  }
  .footer_about >div{
    gap: 30px;
    text-align: center; 
  }
  .footer_about h5{
    font-size: 18px;
  }
}

@media screen and (max-width: 991px) {
  .auth_right  {
    display: none !important;
  }
  .auth_left {
    width: 100%;
    background: linear-gradient(180deg, #18449D 0%, #062A60 100%);
  }
  .auth_left .form-check-label{
    font-size: 14px;
  }
  .auth_footer a{
    font-size: 14px;
    text-align: left !important;
    margin-top: 25px !important;
  }
  .auth_form {
    border-radius: 20px;
background: #FFF;
padding: 20px;
  }
  .copyrights{
    color: #fff;
    font-size: 14px;
  }
  .copyrights .ultag li a{
    color: #fff !important;
  }
  .copyrights .ultag li:not(:last-child):before{
    background: #fff;
  }
  .register_here a{
    font-size: 14px;
  }
  .setting_box{
    flex-direction: column;
    align-items: flex-start;
    white-space: normal;
    word-break: break-all;
  }
  .primary_modal {
    top: unset;
    padding: 0 !important;
    bottom: 0;
    overflow: hidden;
  }
  .primary_modal .modal-dialog{
    max-width: 100%;
    margin: 0;
    min-height: calc(100% - 0px * 2);
    align-items: flex-end;
    transform: translate(0,100%) !important;
  }
  .primary_modal.show .modal-dialog{
    transform: none !important;
  }
  .primary_modal .modal-header {
    padding: 25px;
  }
  .primary_modal .modal-body {
    padding: 25px;
    overflow-y: auto;
    min-height: 460px;
    height: 1px;
  }
  .primary_modal_chgpass .modal-body {
    min-height: 340px;
  }
  .primary_modal .modal-content{
    border-radius: 20px 20px 0px 0px;
  }
}

@media screen and (max-width: 767px) {
  .primary_btn{
    padding: 5px 30px;
    font-size: 14px;
  }
  .custom_tab .nav{
    width: 100%;
  }
  .custom_tab .nav-item{
    width: 50%;
  }
  .custom_tab .nav-link{
    width: 100%;
  }
  .right_box_chg h4 {
    font-size: 16px;
    line-height: unset;
}
.right_box_chg .usdt_img{
  width: 110px;
  margin-left: 0;
}
 
  
  .blue_box h5.grey {
    font-size: 12px;
  }
  .transaction_box h5{
    font-size: 14px;
  }
  .transaction_box td{
    font-size: 14px;
  }
  .transaction_box td .grey{
    font-size: 12px;
  }
  .transaction_box td img{
    width: 35px;
  }
  .transaction_box h5 a{
    font-size: 12px;
  }
  .custom_faq .accordion-button{
    font-size: 16px;
  }
  .primary_modal input {
    font-size: 14px !important;
  }
  .primary_modal .input-group-text {
    font-size: 12px !important;
  }
  .chat_input {
    flex-wrap: wrap;
    row-gap: 10px;
  }
  .choose_file_btn input[type=file] {
    right: 10px;
  }
  .attach_icon {    
    right: 30px;   
  }
  .transaction_box td {
    white-space: normal;
  }
}

@media screen and (max-width: 575px) {

  .usericon_dropdown span {
    display: none;
  }

  h2.page_title {
    font-size: 20px;
  }

  .topnavbar_right {
    gap: 15px;
  }

  .topnavbar_right .dropdown-toggle::after {
    margin-left: 10px;
  }

  .page_content .container-fluid,
  .app_header .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }

  .auth_left h2 {
    font-size: 24px;
  }

  .auth_footer {
    flex-direction: column;
    align-items: unset;
  }

  .auth_left {
    padding: 30px 20px;
  }

  .auth_footer a {
    text-align: center;
    margin-top: 30px;
  }
  .dash_title_flex {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .dash_title_flex button.primary_btn {
    margin-bottom: 10px;
    width: 100%;
  }
  .dash_title_flex button.primary_btn span {
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}


.pageLoader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #062A60;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
